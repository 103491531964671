import { graphql } from 'gatsby'
import { useEffect } from 'react'

export const query = graphql`
  query ServicesPageQuery {
    strapiGraphql {
      servicesPage {
        pageSeo {
          description
          keywords
          title
        }
        service {
          slug
        }
      }
    }
  }
`

export default function Services({ data }) {
  const serviceToRedirect = data.strapiGraphql.servicesPage.service.slug

  useEffect(() => {
    if (serviceToRedirect && typeof window !== 'undefined') {
      window.location.href = `/services/${serviceToRedirect}`
    }
  }, [serviceToRedirect])

  return null
}
